.plans {
  background-color: #000000;
  /* background-color: #5b4ffa; */
}

.table2-shadow {
  box-shadow: 10px 10px 44px 9px rgba(255, 0, 169, 0.4);
  -webkit-box-shadow: 10px 10px 44px 9px rgba(255, 0, 169, 0.4);
  -moz-box-shadow: 10px 10px 44px 9px rgba(255, 0, 169, 0.4);
}
